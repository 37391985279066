import { Section, XAxisLabelType } from './Financials';
import {
  AttachMoney,
  AutoGraph,
  Balance, Battery0Bar,
  BatteryChargingFull,
  Calculate, CarRental,
  CurrencyExchange,
  DirectionsCar,
  DirectionsRun,
  Expand,
  Gavel,
  Lightbulb,
  MoneyOff,
  Nature,
  NextWeek,
  NoCrash,
  Payment,
  Percent,
  PieChart,
  RocketLaunch,
  Share,
  SsidChart,
  Straighten,
  TrendingUp,
  Troubleshoot,
  VerticalAlignBottom,
  VerticalAlignTop,
  VolunteerActivism,
  Work
} from '@mui/icons-material';
import CompareArrows from '@mui/icons-material/CompareArrows';
import BarChartIcon from '@mui/icons-material/BarChart';
import React from 'react';

export const sections: Section[] = [
  {
    name: 'stockPrice',
    label: 'Stock Price',
    unit: { prefix: '$', suffix: '', higherSuffix: '' },
    periodIndependent: true,
    icon: <TrendingUp fontSize="small" />
  },
  {
    name: 'peRatio',
    label: 'Price-to-Earnings Ratio',
    unit: { prefix: '', suffix: '', higherSuffix: '' },
    periodIndependent: true,
    icon: <Calculate fontSize="small" />
  },
  {
    name: 'topline', label: 'Top-Line', unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' }, periodIndependent: false,
    icon: <VerticalAlignTop fontSize="small" />
  },
  {
    name: 'profit',
    label: 'Profitability',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <VerticalAlignBottom fontSize="small" />
  },
  {
    name: 'margins', label: 'Margins', unit: { prefix: '', suffix: '%' }, periodIndependent: false,
    icon: <Percent fontSize="small" />
  },
  {
    name: 'cash', label: 'Cashflow', unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' }, periodIndependent: false,
    icon: <CurrencyExchange fontSize="small" />
  },
  {
    name: 'balanceSheet',
    label: 'Health',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: true,
    icon: <Balance fontSize="small" />
  },
  {
    name: 'performance', label: 'Performance', unit: { prefix: '', suffix: '%' }, periodIndependent: true,
    icon: <DirectionsRun fontSize="small" />
  },
  {
    name: 'eps', label: 'Earnings Per Share', unit: { prefix: '$', suffix: '' }, periodIndependent: false,
    icon: <Lightbulb fontSize="small" />
  },
  {
    name: 'shares',
    label: 'Number of Shares',
    unit: { prefix: '', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: true,
    icon: <Share fontSize="small" />
  },
  {
    name: 'equity', label: 'Equity', unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' }, periodIndependent: false,
    icon: <Nature fontSize="small" />
  },
  {
    name: 'investedCapital',
    label: 'Invested Capital',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: true,
    icon: <VolunteerActivism fontSize="small" />
  },
  {
    name: 'operationalLeverage',
    label: 'Operational Leverage',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <RocketLaunch fontSize="small" />
  },
  {
    name: 'opExVsGrossProfit',
    label: 'Operating Expenses vs. Gross Profit',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <CompareArrows fontSize="small" />
  },
  {
    name: 'opExDistribution',
    label: 'Operating Expense Distribution',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <PieChart fontSize="small" />
  },
  {
    name: 'opExVsCapEx',
    label: 'Operating Expenses vs. Capital Expenditures',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <SsidChart fontSize="small" />
  },
  {
    name: 'opExVsCapExMargin',
    label: 'OpEx vs. CapEx Margin',
    unit: { prefix: '', suffix: '%' },
    periodIndependent: false,
    icon: <Troubleshoot fontSize="small" />
  },
  {
    name: 'epsGrowth', label: 'EPS Growth', unit: { prefix: '', suffix: '%' }, periodIndependent: false,
    icon: <Expand fontSize="small" />
  },
  {
    name: 'netCashOpExCapEx',
    label: 'Net Cash / (OpEx TTM + CapEx TTM)',
    unit: { prefix: '', suffix: '' },
    periodIndependent: true,
    icon: <AutoGraph fontSize="small" />
  },
  {
    name: 'ruleOf40', label: 'Rule of 40', unit: { prefix: '', suffix: '%' }, periodIndependent: true,
    icon: <Straighten fontSize="small" />
  },
  {
    name: 'vehicleDeliveries',
    label: 'Vehicle Deliveries',
    unit: { prefix: '', suffix: '', higherSuffix: 'K' },
    periodIndependent: false,
    xAxisLabelsType: XAxisLabelType.COMPANY_SPECIFIC,
    icon: <NoCrash fontSize="small" />
  },
  {
    name: 'energyStorageDeployed',
    label: 'Energy Storage Deployed',
    unit: { prefix: '', suffix: 'MWhs', higherSuffix: 'GWhs' },
    periodIndependent: false,
    xAxisLabelsType: XAxisLabelType.COMPANY_SPECIFIC,
    icon: <BatteryChargingFull fontSize="small" />
  },
  {
    name: 'energyStorageRevenuePerKwh',
    label: 'Energy Storage Revenue, Cost & Profit Per KWh',
    unit: { prefix: '$', suffix: '' },
    periodIndependent: true,
    xAxisLabelsType: XAxisLabelType.COMPANY_SPECIFIC,
    icon: <Battery0Bar fontSize="small" />
  },
  {
    name: 'revenueBySegment',
    label: 'Revenue By Segment',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <BarChartIcon fontSize="small" />
  },
  {
    name: 'costOfRevenueBySegment',
    label: 'Cost of Revenue By Segment',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <MoneyOff fontSize="small" />
  },
  {
    name: 'grossProfitBySegment',
    label: 'Gross Profit By Segment',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <AttachMoney fontSize="small" />
  },
  {
    name: 'grossMarginsBySegment',
    label: 'Gross Profit Margins By Segment', unit: { prefix: '', suffix: '%' }, periodIndependent: false,
    icon: <Percent fontSize="small" />
  },
  {
    name: 'automotiveRegulatoryCredits',
    label: 'Automotive Regulatory Credit Sales',
    unit: { prefix: '$', suffix: 'M', higherSuffix: 'B' },
    periodIndependent: false,
    icon: <Gavel fontSize="small" />
  },
  {
    name: 'averageVehicleSellingPrice',
    label: 'Average Vehicle Price, Cost & Profit', unit: { prefix: '$', suffix: '' }, periodIndependent: true,
    icon: <DirectionsCar fontSize="small" />
  },
  {
    name: 'automotiveGrossMargin',
    label: 'Automotive Gross Profit Margin', unit: { prefix: '', suffix: '%' }, periodIndependent: false,
    icon: <CarRental fontSize="small" />
  },
  {
    name: 'dividends', label: 'Dividend Per Share', unit: { prefix: '$', suffix: '' }, periodIndependent: false,
    icon: <Work fontSize="small" />
  },
  {
    name: 'dividendYield', label: 'Dividend Yield', unit: { prefix: '', suffix: '%' }, periodIndependent: true,
    icon: <NextWeek fontSize="small" />
  },
  {
    name: 'payoutRatio', label: 'Payout Ratio', unit: { prefix: '', suffix: '%' }, periodIndependent: false,
    icon: <Payment fontSize="small" />
  }
];